import React, {useEffect, useState} from "react";
import { useParams } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import { verifyRegistration } from '../../thunks';

function Verify() {
  const { driverId, code } = useParams()
  const [status, setStatus] = useState(0);

  const isMobilePortrait = useMediaQuery({ maxWidth: 1224, orientation: 'portrait' })
  const isMobileLandscape = useMediaQuery({ maxWidth: 1224, orientation: 'landscape' })

  useEffect(() => {
    verifyRegistration(driverId, code).then(statusCode => {
        setStatus(statusCode);
    })
  }, [code, driverId])


  return (
    <div style={{ marginTop: isMobilePortrait ? '300px' : isMobileLandscape ? '100px'  : '15vw' }}>
      <div style={{ 
        width: isMobilePortrait ? '500px' : isMobileLandscape ? '200px'  :  '10vw',
        margin: 'auto',
      }}>
        <img src="/logo.svg" alt='OnLogist' style={{width: isMobilePortrait ? '500px' : isMobileLandscape ? '200px'  : '10vw'}} />
      </div>

      <div style={{ 
        textAlign: 'center',
        marginTop: '3vw' 
      }}>
          <span style={{ 
            color: '#EC951E',
            fontSize: isMobilePortrait ? 45 : 30
          }}>{[200, 409].includes(status) ? 'Вы успешно зарегистрировались!' : status === 0 ? 'Пожалуйста, подождите...' :  'Что-то пошло не так.'}</span>
          <br /><br /> 
          {isMobilePortrait && <br />}
          <span style={{
            color: '#FFF',
            fontSize: isMobilePortrait ? 30 : 20,
          }}>{[200, 409].includes(status) ? 'Вернитесь в приложение.' : status === 0 ? 'идёт подтверждение регистрации' : 'Попробуйте снова.'}</span>
        </div>
    </div>
  );
}

export default Verify;

