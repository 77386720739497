import React from "react";

function Footer() {
  const helpEmail = 'help@onlogist.ru';
  
  const styleMain = {
    height: '8vw',
    width: '100vw',
    backgroundColor: '#C8A35F',
    display: 'flex',
    justifyContent: 'center',
  }

  return (
    <div style={styleMain}>
      <div style={{ 
        width: '50vw',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        lineHeight: '1.3vw'
      }}>
        <img src="/logo-footer.svg" alt='OnLogist' style={{width: '10vw'}} />
        <div style={{ marginLeft: '2vw' }}>
          <a href={`mailto:${helpEmail}`} style={{ textDecoration: 'none', }}>
            <span style={{ color: '#000' }}>{helpEmail}</span>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;

